import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: baseApiQuery(),
  tagTypes: ["ChatConfig", "ChatStats"],
  endpoints: (builder) => ({
    generateConfig: builder.mutation<any, void>({
      query: () => ({
        url: `/api/chat-providers-customers/providers/chat/config`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["ChatConfig"],
    }),
    getStats: builder.query<any, void>({
      query: () => `/api/chat-providers-customers/providers/channels/stats`,
      providesTags: ["ChatStats"],
    }),
    channelsSearch: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/chat-providers-customers/providers/channels`, queryParams),
    }),
    getChannel: builder.query<any, string>({
      query: (channel_id) => `/api/chat-providers-customers/providers/channels/${channel_id}`,
    }),
    getChannelConfig: builder.query<any, string>({
      query: (channel_id) => ({
        url: `/api/chat-providers-customers/providers/channels/${channel_id}/config`,
        method: "POST",
      }),
    }),
  }),
});
export const { useGetStatsQuery, useGetChannelQuery, useGetChannelConfigQuery, useChannelsSearchQuery } = chatApi;

import { TIMEZONE_CHOICES } from "@/core/constants";
import { OnboardingQuestionType, OnboardingStepType, Step } from "./types";

// !FIXME - this needs to be moved to the Backend

export const getDefaultSteps = (): Step[] => {
  return [
    {
      id: "static",
      slug: "static",
      type: OnboardingStepType.STATIC,
      title: "Static page title",
      description: "This is a static page and you can put a title, description image and action name for the button.",
      image: "/global/images/couch-therapy.webp",
      action: {
        next: {
          title: "Începe chestionarul",
        },
      },
    },
    {
      id: "consent",
      slug: "consent",
      type: OnboardingStepType.CONSENT,
      title: "Consent page",
      description: "This is similar with static page but it has a checkbox for consent.",
      image: "/global/images/couch-therapy.webp",
      question: {
        required: true,
      },
      action: {
        next: {
          title: "Continue",
        },
      },
    },
    {
      id: "timezone",
      slug: "timezone",
      type: OnboardingStepType.TIMEZONE,
      title: "This is a TIMEZONE question",
      description: "TIMEZONE question description",
      question: {
        required: true,
        answers: TIMEZONE_CHOICES,
      },
      action: {
        next: {
          url: "/auth/register",
          title: "Inregistreaza-te",
        },
      },
    },
  ];
};

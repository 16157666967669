import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Channel, StreamChat } from "stream-chat";
import { authSelectors } from "@/core/store/redux/auth/selectors";
import { UserStatusType } from "@/core/store/redux/auth/types";
import { chatActions } from "@/core/store/redux/chat/actions";
import { chatSelectors } from "@/core/store/redux/chat/selectors";
import { ChatStatus } from "@/core/store/redux/chat/types";

type ChatContextType = {
  isConnected: boolean;
  chat_client: StreamChat;
  chatStatus: ChatStatus;
  chat_config: any;
};

export const AppChatChatContext = React.createContext<ChatContextType | null>(null);

const AppChatContext: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const [chat_client, setChatClient] = useState<StreamChat>();
  const config = useSelector(chatSelectors.config);
  const chatStatus = useSelector(chatSelectors.status);

  useEffect(() => {
    dispatch(chatActions.initialize());
  }, []);

  const onClientMessage = useCallback((event: any) => {
    if (event.total_unread_count !== undefined) {
      dispatch(chatActions.setUnreadChannelMessagesCount(event.total_unread_count));
    }
  }, []);

  const connect = useCallback(
    async (c: StreamChat) => {
      const connection = await c.connectUser(
        {
          id: config.user_id,
          total_unread_count: true,
          unread_channels: true,
        },
        config.token,
      );
      if (connection?.me?.total_unread_count) {
        dispatch(chatActions.setUnreadChannelMessagesCount(connection.me.total_unread_count));
      }
      setIsConnected(true);
    },
    [config],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const disconnect = useCallback(
    async (c: StreamChat) => {
      await c.disconnectUser(); // !TODO - check if closeConnection is needed
      setIsConnected(false);
    },
    [config], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!config) return;
    const c = new StreamChat(config.api_key, { timeout: 6000 });
    connect(c).catch(console.error);
    setChatClient(c);
    return () => {
      disconnect(c).catch(console.error);
      setChatClient(undefined);
    };
  }, [config]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //better use notification.message_new || notification.mark_read ?
    const listener = chat_client?.on("all", onClientMessage);
    return listener?.unsubscribe;
  }, [chat_client]);

  if (!chat_client) return <>{children}</>;

  const value = { chat_client, chatStatus, isConnected, chat_config: config };
  return <AppChatChatContext.Provider value={value}>{children}</AppChatChatContext.Provider>;
};

export const AppChatContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(authSelectors.user);
  if (user?.status != UserStatusType.ACTIVE) return <>{children}</>;
  return <AppChatContext>{children}</AppChatContext>;
};

export const useAppChatContext = () => {
  const context = React.useContext(AppChatChatContext);
  return context;
};

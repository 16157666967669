import "@/styles/globals.css";
import "@/styles/getstream.scss";
import "@/styles/calendar.css";

import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import { Provider as ReduxProvider } from "react-redux";

import { AppHead } from "@/components/generic/app/AppHead";
import AppRouting from "@/components/generic/app/AppRouting";
import AppState from "@/components/generic/app/AppState";
import AppTheme from "@/components/generic/app/AppTheme";
import { Toaster } from "@/components/generic/ui/Toaster";
import { appActions } from "@/core/store/redux/app/actions";
import { AppStatus } from "@/core/store/redux/app/types";
import { contextActions } from "@/core/store/redux/context/actions";
import { contextSelectors } from "@/core/store/redux/context/selectors";
import { SiteStatus } from "@/core/store/redux/site/types";
import { ReduxStore } from "@/core/store/types";
import { getHostname } from "@/core/utils/context";
import { deleteCookies, getCookies, saveCookies } from "@/core/utils/cookies";
import { storeWrapper } from "@/store/index";
import { AppRTKToRedux } from "@/components/generic/app/AppRTKToRedux";
import { AppChatContextProvider } from "@/components/context/AppChatContextProvider";

export function MainApp({ Component, ...rest }: AppProps) {
  const { store, props } = storeWrapper.useWrappedStore(rest);

  return (
    <div className="antialiased w-full h-full font-sans" id="__main">
      <ReduxProvider store={store}>
        <AppRouting />
        <AppHead />
        <AppState>
          <AppRTKToRedux />
          <AppTheme>
            <AppChatContextProvider>
              <Component {...props.pageProps} />
              <Toaster className="text-left" />
            </AppChatContextProvider>
          </AppTheme>
        </AppState>
      </ReduxProvider>
    </div>
  );
}

MainApp.getInitialProps = storeWrapper.getInitialAppProps((store: ReduxStore) => async (appContext: AppContext) => {
  const startTime = Date.now();
  const reqContext = appContext.ctx.req ? appContext.ctx : undefined;
  const hostname = getHostname(reqContext);
  const isServer = appContext.ctx.res !== undefined;
  isServer && console.log(`### start ${appContext.ctx.pathname} on ${hostname}`);

  // !FIXME - init the store only once

  // console.log('getInitialProps - MainApp - store.getState()', store.getState());

  // 1. Cookies - store in redux store
  const cookies = getCookies(reqContext);
  store.dispatch(contextActions.setInitialCookies(cookies));

  // 2. Init the core application
  if (store.getState().app.status == AppStatus.LOADING) {
    store.dispatch(
      appActions.initializeBase({
        endSaga: isServer,
        hostname: hostname,
      }),
    );
  }

  // 3. Call the page's `getInitialProps` - might need to move it after init
  const pageProps = await App.getInitialProps(appContext);

  // 4. On the server, wait for saga to end and cancel it
  if (appContext.ctx.res) {
    // On the server side we need to wait for the saga to end and cancel it
    await store.sagaTask.toPromise();
    store.sagaTask.cancel();

    if (store.getState().site.status === SiteStatus.INVALID) {
      console.error(`${hostname} is not a valid site`);
      appContext.ctx.res.statusCode = 404;
    } else if (store.getState().app.status === AppStatus.ERROR) {
      console.error("App is in error");
      appContext.ctx.res.statusCode = 500;
    }
  }

  // 5. Persist any cookie changes from redux store
  const cookiesToDelete = contextSelectors.cookiesToDelete(store.getState());
  deleteCookies(reqContext, cookiesToDelete);
  const cookiesToAdd = contextSelectors.cookiesToSet(store.getState());
  saveCookies(reqContext, cookiesToAdd);

  isServer && console.log(`### end ${appContext.ctx.pathname} took ${Date.now() - startTime}ms`);

  return {
    pageProps: {
      // only way to pass the initialProps from the page
      initialProps: pageProps.pageProps.initialProps ?? {},
    },
  };
});

export default MainApp;

export const AUTH_USER_COOKIE_KEY = "__token";
export const AUTH_GUEST_COOKIE_KEY = "__gutoken";

export const LANGUAGE_CHOICE_DEFAULT = "ro";
export const LANGUAGE_CHOICES = [
  {
    value: "ro",
    label: "Romana",
  },
  // {
  //   value: "en",
  //   label: "Engleza",
  // },
];

export const GENDER_CHOICES = [
  {
    value: "",
    label: "Choose a value...",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "male",
    label: "Male",
  },
  {
    value: "other",
    label: "Other",
  },
  {
    value: "prefer_not_to_say",
    label: "Prefer not to say",
  },
];
export const TIMEZONE_CHOICES = [
  {
    value: "Europe/Bucharest",
    label: "România",
    searchValues: "Romania Bucharest Bucuresti",
    frequent: true,
  },
  {
    value: "Europe/London",
    label: "United Kingdom",
    searchValues: "United Kingdom London England Great Britain",
    frequent: true,
  },
  {
    value: "Europe/Berlin",
    label: "Germany",
    searchValues: "Deutschland Berlin Germany",
    frequent: true,
  },
  {
    value: "Europe/Paris",
    label: "France",
    searchValues: "Paris France French",
    frequent: true,
  },
  {
    value: "Europe/Madrid",
    searchValues: "Spain Madrid Espana",
    label: "Spain",
  },
  {
    value: "Europe/Rome",
    label: "Italy",
  },
  {
    value: "Europe/Amsterdam",
    label: "Netherlands",
  },
  {
    value: "Europe/Brussels",
    label: "Belgium",
  },
  {
    value: "Europe/Vienna",
    label: "Austria",
  },
  {
    value: "Europe/Zurich",
    label: "Switzerland",
  },
  {
    value: "Europe/Stockholm",
    label: "Sweden",
  },
  {
    value: "Europe/Oslo",
    label: "Norway",
  },
  {
    value: "Europe/Copenhagen",
    label: "Denmark",
  },
];

export const BOOKING_HOURS_MINUTES = [
  // 12 AM hours
  { value: "00:00", label: "12:00 AM" },
  { value: "00:30", label: "12:30 AM" },

  // 1 AM hours
  { value: "01:00", label: "1:00 AM" },
  { value: "01:30", label: "1:30 AM" },

  // 2 AM hours
  { value: "02:00", label: "2:00 AM" },
  { value: "02:30", label: "2:30 AM" },

  // 3 AM hours
  { value: "03:00", label: "3:00 AM" },
  { value: "03:30", label: "3:30 AM" },

  // 4 AM hours
  { value: "04:00", label: "4:00 AM" },
  { value: "04:30", label: "4:30 AM" },

  // 5 AM hours
  { value: "05:00", label: "5:00 AM" },
  { value: "05:30", label: "5:30 AM" },

  // 6 AM hours
  { value: "06:00", label: "6:00 AM" },
  { value: "06:30", label: "6:30 AM" },

  // 7 AM hours
  { value: "07:00", label: "7:00 AM" },
  { value: "07:30", label: "7:30 AM" },

  // 8 AM hours
  { value: "08:00", label: "8:00 AM" },
  { value: "08:30", label: "8:30 AM" },

  // 9 AM hours
  { value: "09:00", label: "9:00 AM" },
  { value: "09:30", label: "9:30 AM" },

  // 10 AM hours
  { value: "10:00", label: "10:00 AM" },
  { value: "10:30", label: "10:30 AM" },

  // 11 AM hours
  { value: "11:00", label: "11:00 AM" },
  { value: "11:30", label: "11:30 AM" },

  // 12 PM hours
  { value: "12:00", label: "12:00 PM" },
  { value: "12:30", label: "12:30 PM" },

  // 1 PM hours (Remember to start from 13 since it's technically PM)
  { value: "13:00", label: "1:00 PM" },
  { value: "13:30", label: "1:30 PM" },

  // 2 PM hours
  { value: "14:00", label: "2:00 PM" },
  { value: "14:30", label: "2:30 PM" },

  // 3 PM hours
  { value: "15:00", label: "3:00 PM" },
  { value: "15:30", label: "3:30 PM" },

  // 4 PM hours
  { value: "16:00", label: "4:00 PM" },
  { value: "16:30", label: "4:30 PM" },

  // 5 PM hours
  { value: "17:00", label: "5:00 PM" },
  { value: "17:30", label: "5:30 PM" },

  // 6 PM hours
  { value: "18:00", label: "6:00 PM" },
  { value: "18:30", label: "6:30 PM" },

  // 7 PM hours
  { value: "19:00", label: "7:00 PM" },
  { value: "19:30", label: "7:30 PM" },

  // 8 PM hours
  { value: "20:00", label: "8:00 PM" },
  { value: "20:30", label: "8:30 PM" },

  // 9 PM hours
  { value: "21:00", label: "9:00 PM" },
  { value: "21:30", label: "9:30 PM" },

  // 10 PM hours
  { value: "22:00", label: "10:00 PM" },
  { value: "22:30", label: "10:30 PM" },

  // 11 PM hours
  { value: "23:00", label: "11:00 PM" },
  { value: "23:30", label: "11:30 PM" },
];

export const BOOKING_LENGTH_MINUTES = [
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "50", label: "50" },
  { value: "60", label: "60" },
];

export const BOOKING_EXPIRY_MINUTES = [
  { value: "60", label: "1 Hour" },
  { value: "180", label: "3 Hours" },
  { value: "360", label: "6 Hours" },
  { value: "1440", label: "1 day" },
  { value: "4320", label: "3 days" },
  { value: "8640", label: "6 days" },
];

export const BOOKING_MIN_TIME_BEFORE_START = [
  { value: "20", label: "20 Minutes" },
  { value: "30", label: "30 Minutes" },
  { value: "60", label: "1 Hour" },
  { value: "120", label: "2 Hours" },
  { value: "180", label: "3 Hours" },
  { value: "360", label: "6 Hours" },
  { value: "1440", label: "1 day" },
  { value: "4320", label: "3 days" },
  { value: "8640", label: "6 days" },
];

export const BOOKING_BREAK_MINUTES = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "60", label: "60" },
];

import { NextSeo, NextSeoProps } from "next-seo";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { siteSelectors } from "@/core/store/redux/site/selectors";
import { SiteStatus } from "@/core/store/redux/site/types";

export type AppHeadProps = NextSeoProps;

export const AppHead: React.FC<AppHeadProps> = ({ title, description, canonical, ...props }) => {
  const status = useSelector(siteSelectors.siteStatus);
  const appDomain = useSelector(siteSelectors.appDomain);
  const siteConfig = useSelector(siteSelectors.siteConfig);

  const additionalLinkTags = useMemo(() => {
    if (status != SiteStatus.CONFIGURED) return [];
    return [
      {
        rel: "icon",
        href: `${siteConfig.siteAssetsBase}/images/favicon.ico`,
      },
      {
        rel: "apple-touch-icon",
        href: `${siteConfig.siteAssetsBase}/images/webclip.png`,
      },
    ];
  }, [siteConfig.siteAssetsBase, status]);

  const customTitle = title ? `${siteConfig.siteName} | ${title}` : `${siteConfig.siteName}`;
  const customDescription = description || siteConfig.siteDescription;
  const customCanonical = `https://${appDomain}/${canonical}`;
  const additionalMetaTags = [
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
  ];

  return (
    <NextSeo
      {...props}
      title={customTitle}
      description={customDescription}
      canonical={customCanonical}
      additionalLinkTags={additionalLinkTags}
      additionalMetaTags={additionalMetaTags}
      nofollow={true}
      noindex={true} // Nothing shall be indexed
    >
      {props.children}
    </NextSeo>
  );
};

export const BadDomain: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="grow py-6">
        <main className="grid min-h-full place-items-center px-6 py-24 sm:py-48 lg:px-8">
          <div className="text-center">
            <p className="text-lg font-bold text-red-700">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Invalid Domain</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Whoops! This domain is not known by us.</p>
          </div>
        </main>
      </main>
    </div>
  );
};

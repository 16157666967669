import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

export const customersApi = createApi({
  reducerPath: "customersApi",
  baseQuery: baseApiQuery(),
  tagTypes: ["AllocationStats", "Allocations"],
  endpoints: (builder) => ({
    // Allocation
    getAllocationStats: builder.query<any, void>({
      query: () => `/api/providers-customers/providers/customer-allocations/stats`,
      providesTags: ["AllocationStats"],
    }),
    getCountAllocations: builder.query<any, void>({
      query: () => `/api/providers-customers/providers/customer-allocations/count`,
      providesTags: ["AllocationStats"],
    }),
    allocationsSearch: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-customers/providers/customer-allocations`, queryParams),
      providesTags: ["Allocations"],
    }),
    getAllocatedCustomerFullDetails: builder.query<any, string>({
      query: (customer_id) => `/api/providers-customers/providers/customer-allocations/${customer_id}/detailed`,
    }),
  }),
});
export const {
  useGetAllocationStatsQuery,
  useAllocationsSearchQuery,
  useLazyAllocationsSearchQuery,
  useGetAllocatedCustomerFullDetailsQuery,
  useGetCountAllocationsQuery,
} = customersApi;

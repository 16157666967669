import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ActionItemState } from "./types";

const getInitialState = (): ActionItemState => {
  return {
    timezoneMismatch: undefined,
    activeBooking: undefined,
    isPendingApproval: undefined,
  };
};

export const actionItemsReducerSlice = createSlice({
  name: "actionItems",
  initialState: getInitialState(),
  reducers: {
    setTimezoneMismatch: (state, action: PayloadAction<boolean>) => {
      state.timezoneMismatch = action.payload;
    },
    setActiveBooking: (state, action: PayloadAction<any | undefined>) => {
      state.activeBooking = action.payload;
    },
    setIsPendingApproval: (state, action: PayloadAction<boolean | undefined>) => {
      state.isPendingApproval = action.payload;
    },
  },
});

import { FeaturesConfig } from "../features/types";
import { SiteLinks, ThemeConfig } from "../theme/types";

export enum SiteStatus {
  NA = "NA",
  CONFIGURED = "CONFIGURED",
  ERROR = "ERROR",
  INVALID = "INVALID",
}

export interface SiteState {
  status: SiteStatus;
  code?: string;
  appDomain?: string;
  config: SiteConfig;
}

export const SiteCodeInvalid = "INVALID";

type BaseSiteConfig = {
  siteName: string;
  siteDescription: string;
  siteAssetsBase: string;
  themeConfig?: ThemeConfig;
  links?: SiteLinks;
  features?: FeaturesConfig;
};

export type SiteConfig = BaseSiteConfig & Record<string, any>;

import { createApi } from "@reduxjs/toolkit/query/react";

import { baseApiQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

export const agreementsApi = createApi({
  reducerPath: "agreementsApi",
  baseQuery: baseApiQuery(),
  tagTypes: ["Agreements"],
  endpoints: (builder) => ({
    agreementsSearch: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) =>
        addQueryDictToUrl(`/api/providers-customers-agreements/providers/agreements`, queryParams),
      providesTags: ["Agreements"],
    }),
    getAgreementFile: builder.query<any, string>({
      query: (id) => `/api/providers-customers-agreements/providers/agreements/${id}/download`,
    }),

    // OLD
    getAgreements: builder.query<ListResponse<any>, number | void>({
      query: (page = 1) => `/api/providers-customers-agreements/customers/agreements?page=${page}`,
      providesTags: ["Agreements"],
    }),
    getAgreementsUnsignedCount: builder.query<any, void>({
      query: () => `/api/providers-customers-agreements/customers/agreements/unsigned-count`,
      providesTags: ["Agreements"],
    }),
    getAgreementDetails: builder.query<any, string>({
      query: (id) => `/api/providers-customers-agreements/customers/agreements/${id}`,
    }),
    signAgreement: builder.mutation<any, void>({
      query: (id) => ({
        url: `/api/providers-customers-agreements/customers/agreements/${id}/sign`,
        method: "POST",
      }),
      invalidatesTags: ["Agreements"],
    }),
  }),
});
export const {
  useAgreementsSearchQuery,
  useLazyAgreementsSearchQuery,
  useGetAgreementsQuery,
  useGetAgreementsUnsignedCountQuery,
  useGetAgreementDetailsQuery,
  useSignAgreementMutation,
  useGetAgreementFileQuery,
} = agreementsApi;

import { put, select, takeEvery } from "redux-saga/effects";

import { chatApi } from "@/core/api/services/chat";
import { chatActions } from "./actions";
import { chatSelectors } from "./selectors";
import { ChatConfig, ChatStatus } from "./types";
import { callRTKQuery } from "../api/sagas";

function* onInitialize(): Generator {
  const status = yield select(chatSelectors.status);
  if (status != ChatStatus.NA) {
    return;
  }

  try {
    yield put(chatActions.setStatus(ChatStatus.LOADING));
    const data = yield callRTKQuery(chatApi.endpoints.generateConfig.initiate());
    yield put(chatActions.setConfig(data as ChatConfig));
    yield put(chatActions.setStatus(ChatStatus.CONFIGURED));
  } catch (err) {
    console.error("Failed to fetch chat config", err);
    yield put(chatActions.setStatus(ChatStatus.ERROR));
  }
}

export default function* sagas(): Generator {
  yield takeEvery(chatActions.initialize, onInitialize);
}

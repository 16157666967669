import { all } from "redux-saga/effects";

import appSagas from "./app/sagas";
import authSagas from "./auth/sagas";
import chatSagas from "./chat/sagas";
import contextSagas from "./context/sagas";
import onboardingSagas from "./onboarding/sagas";
import onboardingProgressSagas from "./onboardingProgress/sagas";
import routerSagas from "./router/sagas";
import settingsSagas from "./settings/sagas";
import siteSagas from "./site/sagas";
import themeSagas from "./theme/sagas";
import featuresSagas from "./features/sagas";
import actionItemsSagas from "./actionItems/sagas";

export default function* rootSaga() {
  yield all([
    appSagas(),
    authSagas(),
    contextSagas(),
    routerSagas(),
    siteSagas(),
    chatSagas(),
    onboardingSagas(),
    onboardingProgressSagas(),
    themeSagas(),
    settingsSagas(),
    featuresSagas(),
    actionItemsSagas(),
  ]);
}
